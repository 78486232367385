import {Inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Environment} from '../../environments/environment.type';

@Injectable({
    providedIn: 'root'
})
export class StaticAuthHeaderInterceptor implements HttpInterceptor {
    constructor(@Inject('Environment') public environment: Environment) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.requiresToken(req) && this.environment.tokenType === 'static') {
            return this.interceptWithToken(req, next, 'token');
        }
        return next.handle(req);
    }

    private interceptWithToken(req: HttpRequest<any>, next: HttpHandler, token: string): Observable<HttpEvent<any>> {
        req = req.clone({headers: req.headers.set('Authorization', 'Bearer ' + token)});
        return next.handle(req);
    }

    private requiresToken(req: HttpRequest<any>): boolean {
        return [this.environment.apiUrl, this.environment.bmiUrl].some((url: string): boolean => req.url.startsWith(url));
    }
}

