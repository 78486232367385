import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {Client} from './client.type';
import {Environment} from '../../../environments/environment.type';

@Injectable({
    providedIn: 'root'
})
export class ClientsService {
    private readonly _clients: Subject<Client[]> = new ReplaySubject<Client[]>(1);
    private readonly clients$: Observable<Client[]> = this._clients.asObservable();

    constructor(private http: HttpClient,
                @Inject('Environment') private environment: Environment) {
        this.http.get<Client[]>(`${this.environment.apiUrl}/clients`)
            .subscribe((clients: Client[]): void => this._clients.next(clients));
    }

    getClients(): Observable<Client[]> {
        return this.clients$;
    }
}
