import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable, ReplaySubject} from 'rxjs';
import {ColumnData, ColumnView, DetectPreview, PreviewHeader, PreviewPrimeHeader} from './import-products-preview';
import {Authentication} from '../../services/resource/resource.type';
import {ImageType} from '@bgenius/bgf-angular';


export enum ColumnDataType {
    IMAGE_URL = 'Image', NUMBER = 'Number', TEXT = 'Text'
}


@Injectable({
    providedIn: 'root'
})
export class ImportProductsService {
    _detectPreview: ReplaySubject<DetectPreview> = new ReplaySubject<DetectPreview>(1);
    detectPreview$: Observable<DetectPreview> = this._detectPreview.asObservable();

    constructor(private http: HttpClient) {
    }

    get detectPreview(): Observable<DetectPreview> {
        return this.detectPreview$;
    }

    set currentDetectPreview(detectPreview: DetectPreview) {
        this._detectPreview.next(detectPreview);
    }

    detectResource(idClient: number, idStore: number, fileUri: string, hasLogin: boolean, authentication: Authentication): Observable<DetectPreview> {
        return this.http.post<DetectPreview>(`${environment.apiUrl}/client/${idClient}/store/${idStore}/resource/detect`, {
            uri: fileUri,
            authentication
        });
    }

    finalizeImport(idClient: number, idStore: number, resource: DetectPreview): Observable<DetectPreview> {
        return this.http.post<DetectPreview>(
            `${environment.apiUrl}/client/${idClient}/store/${idStore}/resource`,
            resource,
            {params: {idClient, idStore}});
    }

    createPreviewTable(detectPreview: DetectPreview): Array<{ [key: string]: string }> {
        let previewTableHeaders: PreviewPrimeHeader[] = this.createPrimeHeaders(detectPreview.schema.columns);
        let previewTableDataArray: Array<{ [key: string]: string }> = [];
        detectPreview.preview.rows.forEach((row: { values: string[] }): void => {
            const previewTableData: { [key: string]: string } = {};
            row.values.forEach((value: string, index: number): string =>
                previewTableData[previewTableHeaders[index].headerValue] = value);
            previewTableDataArray.push(previewTableData);
        });
        return previewTableDataArray;
    }

    createPrimeHeaders(columns: PreviewHeader[]): PreviewPrimeHeader[] {
        const previewHeaders: PreviewPrimeHeader[] = columns.map((tableHeader: PreviewHeader): PreviewPrimeHeader => ({
            ...tableHeader,
            headerName: tableHeader.title,
            headerValue: tableHeader.title.replace(/\./g, '_'), //TODO: remove replace after the breaking function has removed from gem
            viewType: {
                type: ColumnView[tableHeader.type],
                imageType: ImageType.ProductThumbnail
            },
            dataType: {
                type: ColumnData[tableHeader.type]
            }
        }));

        return previewHeaders.sort((a: PreviewHeader, b: PreviewHeader): number => a.position - b.position);
    }
}
