import {Directive, OnInit} from '@angular/core';
import {WhiteLabel, WHITELABELS} from './types/white-labels';
import {UserService} from '../../services/user-service/user.service';
import {User} from '../../model/user';
import {AuthenticatedResult, OidcSecurityService} from 'angular-auth-oidc-client';

@Directive({
    standalone: true,
    selector: '[whitelabelInitializer]',
})
export class BgWhitelabelInitializer implements OnInit {
    private appliedWhitelabel: string | undefined;
    private readonly WHITELABEL_FALLBACK: string = 'bgenius';
    private readonly WHITELABEL_SCRIPT_ID: string = 'whitelabel';
    userFetched: boolean = false;

    constructor(private userService: UserService, private oidcSecurityService: OidcSecurityService) {
    }

    ngOnInit(): void {
        this.oidcSecurityService.isAuthenticated$.subscribe({
            next: (authenticatedResult: AuthenticatedResult): void => {
                if (authenticatedResult.isAuthenticated && !this.userFetched) {
                    this.userFetched = true;
                    this.userService.fetchUser().subscribe({
                        next: (user: User): void => {
                            this.appliedWhitelabel = user.whiteLabel || this.WHITELABEL_FALLBACK;
                            if (this.appliedWhitelabel !== this.WHITELABEL_FALLBACK) {
                                this.initWhitelabel();
                            }
                        }
                    })
                }
            }
        })
    }

    initWhitelabel(): void {
        const headElement: HTMLHeadElement = document.querySelector('head')!;
        const currentWhitelabelScriptElement: HTMLElement = document.getElementById(this.WHITELABEL_SCRIPT_ID)!;
        const favIconElement: any = document.getElementById('favicon');
        let favIconPath: string = '';
        if (this.appliedWhitelabel) {
            const selectedWhitelabel: WhiteLabel = WHITELABELS.values
                .find(({value}): boolean => value === this.appliedWhitelabel)!;
            favIconPath = selectedWhitelabel.url + '/white-label/';

            if (!currentWhitelabelScriptElement) {
                headElement.appendChild(this.createWhitelabelScriptElement(selectedWhitelabel));
            }
            if (currentWhitelabelScriptElement && currentWhitelabelScriptElement.dataset[this.WHITELABEL_SCRIPT_ID] !== selectedWhitelabel.value) {
                currentWhitelabelScriptElement.replaceWith(this.createWhitelabelScriptElement(selectedWhitelabel));
            }
        }
        favIconElement.href = favIconPath + 'favicon.ico';
    }

    createWhitelabelScriptElement(whitelabel: WhiteLabel): HTMLScriptElement {
        const scriptElement: HTMLScriptElement = document.createElement('script');
        scriptElement.id = this.WHITELABEL_SCRIPT_ID;
        scriptElement.dataset[this.WHITELABEL_SCRIPT_ID] = whitelabel.value;
        scriptElement.src = `${whitelabel.url}${WHITELABELS.whitelabelPath}`;
        return scriptElement;
    };
}
