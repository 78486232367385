import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SingleDropDownSelectorOption} from '@bgenius/bgf-angular/lib/dropdown-selector/selector.type';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ClientService} from '../../services/client-service/client.service';
import {ClientsService} from '../../services/client-service/clients.service';
import {Client} from '../../services/client-service/client.type';

@UntilDestroy()
@Component({
    selector: 'clients-dropdown',
    templateUrl: './client-dropdown.component.html'
})
export class ClientsDropdownComponent implements OnInit {
    clientOptions: SingleDropDownSelectorOption[] = [];

    constructor(private router: Router,
                private clientService: ClientService,
                private clientsService: ClientsService) {
    }

    ngOnInit(): void {
        this.clientService
            .getClient()
            .pipe(untilDestroyed(this))
            .subscribe((client: Client) => this.getClients(client.id));
    }

    private getClients(currentClientId: number): void {
        this.clientsService
            .getClients()
            .pipe(untilDestroyed(this))
            .subscribe((clients: Client[]): SingleDropDownSelectorOption[] => this.clientOptions = this.makeOptions(clients, currentClientId));
    }

    private makeOptions(clients: Client[], currentClientId: number): SingleDropDownSelectorOption[] {
        const sorted: Client[] = clients.sort((a: Client, b: Client): number => this.clientNameComparator(a,b));
        return sorted
            .map((client: Client): SingleDropDownSelectorOption => ({
                name: client.name,
                value: client.id,
                isSelected: currentClientId === client.id
            }));
    }

    private clientNameComparator(a: Client, b: Client): number {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
        }
        return 0;
    }

    handleClientSelection(option: SingleDropDownSelectorOption[]): void {
        this.router.navigateByUrl(`/clients/${option[0].value}/product-center`);
    }
}
