import {Component, Inject} from '@angular/core';
import {ClientService} from '../services/client-service/client.service';
import {Environment} from 'src/environments/environment.type';
import {UserService} from '../services/user-service/user.service';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Router} from '@angular/router';

@UntilDestroy()
@Component({
    selector: 'bg-main-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class MainHeaderComponent {
    currentYear: number = (new Date()).getFullYear();
    userMenuToggle: boolean = false;

    constructor(public userService: UserService,
                public clientService: ClientService,
                private oidcSecurityService: OidcSecurityService,
                private router: Router,
                @Inject('Window') public window: Window,
                @Inject('Environment') public environment: Environment) {
    }

    redirect(url: string): void {
        this.router.navigate(['redirect-external'], {queryParams: {externalUrl: url}});
    }

    logoff() : void {
        this.oidcSecurityService.logoff()
            .pipe(untilDestroyed(this))
            .subscribe();
    }
}
