import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AutoLoginAllRoutesGuard} from 'angular-auth-oidc-client';
import {LoginComponent} from './auth/login/login.component';
import {clientGuard} from './services/client-service/client-guard/client.guard';

const routes: Routes = [
    {path: 'login', pathMatch: 'full', component: LoginComponent}, // no guards here
    {
        path: 'home',
        pathMatch: 'full',
        loadChildren: () => import('./landing-page/landing-page.module').then(m => m.LandingPageModule),
    }, {
        path: 'clients/:clientId/product-center',
        canActivateChild: [AutoLoginAllRoutesGuard, clientGuard],
        loadChildren: () => import('./overview/overview.module').then(m => m.OverviewModule)
    }, {
        path: 'clients/:clientId/mapping',
        canActivateChild: [AutoLoginAllRoutesGuard, clientGuard],
        loadChildren: () => import('./mapping/mapping.module').then(m => m.MappingModule)
    }, {
        path: 'clients/:clientId/import-settings',
        canActivateChild: [AutoLoginAllRoutesGuard, clientGuard],
        loadChildren: () => import('./import-settings/import-settings.module').then(m => m.ImportSettingsModule)
    }, {
        path: 'clients/:clientId/import-products',
        canActivateChild: [AutoLoginAllRoutesGuard, clientGuard],
        loadChildren: () => import('./import-products/import-products.module').then(m => m.ImportProductsModule)
    },
    {path: 'redirect-external', pathMatch: 'full', loadChildren: () => import('@bgenius/bgf-angular').then(m => m.ExternalRedirectModule)},
    {path: '', redirectTo: '/home', pathMatch: 'full'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
