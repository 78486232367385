import {TitleCasePipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';
import {getUserDataAttribute, UserData, UserDataAttributes} from './user.data.type';

export type UserDataValues = keyof UserDataAttributes | 'fullname' | 'initials';

@Pipe({name: 'userData'})
export class UserDataPipe implements PipeTransform {
    constructor(private titleCasePipe: TitleCasePipe) {
    }

    transform(result: UserData | null, attribute: UserDataValues): string {
        if (!result) {
            return '';
        }

        switch (attribute) {
            case 'fullname':
                return this.fullname(result).trim();
            case 'initials':
                return this.initials(result);
            default:
                return this.get(result, attribute);
        }
    }

    private initials(userData: UserData) : string {
        const first: string = this.get(userData, 'firstName');
        const last: string = this.get(userData, 'lastName');
        return (first.charAt(0) + last.charAt(0)).toUpperCase();
    }

    private fullname(userData: UserData): string {
        return this.get(userData, 'firstName') + ' ' + this.get(userData, 'lastName');
    }

    private get(userData: UserData, attribute: keyof UserDataAttributes): string {
        const value: string = getUserDataAttribute(userData, attribute).trim();
        return this.titleCasePipe.transform(value);
    }
}
