import {Component, Inject, OnInit} from '@angular/core';
import {Environment} from '../environments/environment.type';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {UserService} from './services/user-service/user.service';

@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(private userService: UserService,
                private oidcSecurityService: OidcSecurityService,
                @Inject('Environment') private environment: Environment) {
    }

    ngOnInit() {
        if (this.environment.name === 'local') {
            this.userService.setLocalUser();
        } else {
            this.oidcSecurityService.checkAuth()
                .pipe(untilDestroyed(this))
                .subscribe({
                    next: ({isAuthenticated, userData}): void => {
                        if (isAuthenticated) {
                            this.userService.setUser(userData);
                        } else {
                            this.oidcSecurityService.authorize();
                        }
                    }
                });
        }
    }
}
