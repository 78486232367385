export interface UserData {
    attributes: UserDataAttributes;
}

export interface UserDataAttributes {
    firstName: string;
    lastName: string;
    scope: string;
    urn: string;
}

export const getUserDataAttribute = (userData: UserData, attribute: keyof UserDataAttributes): string =>
    userData.attributes[attribute];

export const LOCAL_DEVELOPER: UserData = {
    attributes: {
        firstName: 'Developer',
        lastName: 'Kinesso',
        scope: 'local',
        urn: 'local'
    }
};
