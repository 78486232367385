import {Environment} from './environment.type';

export const environment: Environment = {
    clientId: "563cf963-5e71-410f-b259-eda1f72dc1ef",
    production: true,
    name: 'prod',
    tokenType: 'dynamic',
    frontendUrl: 'https://pc.bgenius.com',
    apiUrl: 'https://pc.bgenius.com/api',
    daAppUrl: 'https://da.bgenius.com',
    accessAndSettingsUrl: 'https://mie.bgenius.com/access-settings/',
    openIdUrl: "https://accounts.bgenius.com/oidc",
    bmiUrl: "https://api.bgenius.com/data/v0",
    clUrl: 'https://mie.bgenius.com/access-settings/',
    fmUrl: 'https://fm.bgenius.com/fm',
    daUrl: 'https://da.bgenius.com/da',
    cmUrl:'https://app.bgenius.com',
    cdUrl: 'https://mie.bgenius.com/content-discovery/cd',
    dxaUrl: 'https://dxa.kinesso.com',
    mieUrl: 'https://mie.kinesso.com/shell/#/login/cas',
    atUrl: 'https://mie.bgenius.com/activation-triggers'
};
