import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {environment} from 'src/environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {
    BgBlockModule,
    BgButtonModule,
    BgDropdownSelectorModule,
    BgIconModule,
    BgLibraryModule,
    BgPageLayoutModule,
    BgPrimeTableModule,
    BgSideMenuModule,
    BgToastModule,
    HeaderModule
} from '@bgenius/bgf-angular';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthConfigModule} from './auth/auth-config.module';
import {UserDataPipe} from './pipes/user-data/user.data.pipe';
import {TitleCasePipe} from '@angular/common';
import {ClientsDropdownComponent} from './header/clients-dropdown/clients-dropdown.component';
import {MainHeaderComponent} from './header/header.component';
import {AuthInterceptor} from 'angular-auth-oidc-client';
import {StaticAuthHeaderInterceptor} from './auth/static-auth-header-interceptor.service';
import {MessageService} from 'primeng/api';
import {MappingTableModule} from './mapping-table/mapping-table.module';
import {BgWhitelabelInitializer} from './directives/whitelabel-initializer/whitelabel-initializer.directive';

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        AppComponent,
        MainHeaderComponent,
        ClientsDropdownComponent,
        UserDataPipe
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BgLibraryModule,
        BgPageLayoutModule,
        BrowserAnimationsModule,
        AuthConfigModule,
        HeaderModule,
        BgIconModule,
        BgSideMenuModule,
        BgDropdownSelectorModule,
        BgBlockModule,
        BgButtonModule,
        BgPrimeTableModule,
        MappingTableModule,
        BgToastModule,
        BgWhitelabelInitializer
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: StaticAuthHeaderInterceptor, multi: true},
        {provide: 'Window', useValue: window},
        {provide: 'Environment', useValue: environment},
        TitleCasePipe,
        MessageService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
