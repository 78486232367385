import {Pipe, PipeTransform} from '@angular/core';
import {ColumnDataType} from "../../import-products/services/import-products.service";

@Pipe({name: 'columnTypePipe'})
export class ColumnTypePipe implements PipeTransform {
    transform(result: string): string {
        if ((<any>ColumnDataType)[result] === undefined){
            return 'Text'
        }
        return (<any>ColumnDataType)[result];
    }
}
