import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, ReplaySubject} from 'rxjs';
import {Client} from './client.type';
import {Environment} from '../../../environments/environment.type';

@Injectable({
    providedIn: 'root'
})
export class ClientService {
    private readonly _client: ReplaySubject<Client> = new ReplaySubject<Client>(1);
    public readonly client$: Observable<Client> = this._client.asObservable();
    client: Client | undefined;

    constructor(private http: HttpClient,
                @Inject('Environment') private environment: Environment) {
    }

    fetchClient(clientId: string | number): Observable<Client> {
        return this.http.get<Client>(`${this.environment.apiUrl}/clients/${clientId}`);
    }

    setCurrentClient(client: Client): void {
        if (this.client && this.client.id === client.id) {
            return;
        }
        this.client = client;
        this._client.next(client);
    }

    getClient(): Observable<Client> {
        return this.client$;
    }
}
