import {TableContentDataType, TableContentViewType, TableHeader} from '@bgenius/bgf-angular';
import {MappingTableRow} from '../../mapping-table/mapping-table.component';

export type ColumnType = 'IMAGE_URL' | 'TEXT' | 'NUMBER';
export const ColumnView: {[key in ColumnType]: TableContentViewType} = {
    IMAGE_URL: 'image',
    TEXT: 'text',
    NUMBER: 'text'
};

export const ColumnData: {[key in ColumnType]: TableContentDataType} = {
    IMAGE_URL: 'string',
    TEXT: 'string',
    NUMBER: 'number'
};

export interface PreviewHeader {
    title: string;
    options: string | null;
    type: ColumnType;
    position: number;
    path: string;
    storeColumn: {
        idColumn: number | null;
        title: string;
        options: string | null;
        type: ColumnType;
        position: number;
        order: number;
    }
}

export interface PreviewPrimeHeader extends PreviewHeader, TableHeader {}

export interface DetectPreview {
    uri: string,
    name?: string;
    authentication: {
        type: 'NONE' | 'USERNAME_PASSWORD';
        username?: string;
        password?: string;
    },
    format: {
        type: 'CSV',
        delimiter: string,
        quotes: string
    },
    protocol: {
        type: 'HTTP'
    },
    schema: {
        columns: PreviewHeader[]
    },
    preview: {
        header: {values: string[]},
        rows: Array<{values: string[]}>
    }
}

export const tableTitle = (tableDataLength: number): string => {
    return tableDataLength === 1 ? 'Preview (first row)' : `Preview (first ${tableDataLength} rows)`;
};

export const toMappingTableRow: (column: PreviewHeader) => MappingTableRow = (column: PreviewHeader): MappingTableRow => {
    return {
        storeColumnType: column.type,
        importColumnType: column.type,
        importColumnValue: column.title,
        storeColumnValue: column.title,
        updateNameValue: (value: string): string => column.storeColumn.title = value,
        updateTypeValue: (value: ColumnType): string => column.storeColumn.type = value
    };
}
