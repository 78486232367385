import {NgModule} from '@angular/core';
import {AuthModule, LogLevel} from 'angular-auth-oidc-client';
import {environment} from '../../environments/environment';

@NgModule({
    imports: [AuthModule.forRoot({
        config: {
            configId: 'bgenius',
            authority:  environment.openIdUrl,
            redirectUrl: environment.frontendUrl + '/login',
            postLogoutRedirectUri: window.location.origin,
            clientId: environment.clientId,
            scope: 'openid email bgenius',
            responseType: 'code',
            silentRenew: false,
            renewTimeBeforeTokenExpiresInSeconds: 60,
            customParamsAuthRequest: {'service': window.location.origin},
            logLevel: environment.production ? LogLevel.None : LogLevel.Debug,
            secureRoutes: environment.tokenType === 'static' ? [] : [environment.apiUrl, environment.bmiUrl]
        }
    })],
    exports: [AuthModule],
})
export class AuthConfigModule {
}
