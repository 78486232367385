import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {FormControl, FormGroup} from '@angular/forms';
import {mappingInputsValidator} from '../validators/mapping-inputs.validator';
import {ColumnType} from '../import-products/services/import-products-preview';
import {Router} from '@angular/router';
import {ColumnDataType} from '../import-products/services/import-products.service';
import {SingleDropDownOption} from '@bgenius/bgf-angular/lib/dropdown-list/dropdown.type';
import {Subscription} from 'rxjs';

export interface MappingTableRow {
    importColumnValue: string;
    storeColumnValue: string;
    importColumnType: ColumnType;
    storeColumnType: ColumnType;
    updateNameValue: (value: string) => any;
    updateTypeValue: (value: ColumnType) => any;
}

@UntilDestroy()
@Component({
    selector: 'bg-mapping-table',
    templateUrl: './mapping-table.component.html',
    styleUrls: ['./mapping-table.component.scss']
})
export class MappingTableComponent implements OnChanges {
    tableHeaders: ReadonlyArray<string> = ['Import Column', 'Store Column', 'Type'];
    public form: FormGroup = new FormGroup([]);
    @Input() rows: MappingTableRow[] = [];
    @Output() dirty: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
    loading: boolean = true;
    typeNotAvailable: boolean = false;
    availableTypes: Record<string, SingleDropDownOption[]> = {};
    formChangesSubscription: Subscription | undefined;

    constructor(private router: Router) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.typeNotAvailable = this.router.url.split('/')[3] === 'import-products';
        this.rows.forEach((row: MappingTableRow): void => {
            this.availableTypes = {...this.availableTypes, [row.importColumnValue]: this.createAvailableType(row)};
        });

        if (this.formChangesSubscription) {
            this.formChangesSubscription.unsubscribe();
        }
        this.rows.forEach((row: MappingTableRow): void => {
            this.form.addControl(
                row.storeColumnValue,
                new FormControl(row.storeColumnValue, [mappingInputsValidator(this.rows)])
            );
            this.form.addControl(
                'type' + row.storeColumnValue,
                new FormControl(row.storeColumnType)
            );
            this.loading = false;
        })
        this.onCreateGroupFormValueChange();
    }

    onCreateGroupFormValueChange(): void {
        const initialValue: any = this.form.value
        this.formChangesSubscription = this.form.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((): void => {
                const hasChange: boolean = this.checkIsChanged(initialValue)

                this.dirty.emit(hasChange);
                this.valid.emit(this.form.valid);
            });
    }

    updateNameValue(row: MappingTableRow, $event: string | number): void {
        row.storeColumnValue = String($event);
        row.updateNameValue(String($event));
    }

    updateTypeValue(row: MappingTableRow, type: SingleDropDownOption[]): void {
        row.updateTypeValue(type[0].value);
        this.availableTypes[row.importColumnValue].forEach((aType: SingleDropDownOption): void => {
            aType.isSelected = aType.value === type[0].value;
        })
    }

    createAvailableType(row: MappingTableRow): SingleDropDownOption[] {
        let results: SingleDropDownOption[] = []
        const keys: string[] = Object.keys(ColumnDataType);
        const values: ColumnDataType[] = Object.values(ColumnDataType);
        for (let i: number = 0; i < keys.length; i++) {
            results = [...results, {value: keys[i], name: values[i], isSelected: row.importColumnType === keys[i]}];
        }
        return results;
    }

    checkIsChanged(initialValue: any): boolean {
        return Object.keys(initialValue).some((key: string): boolean => {
            if (key.startsWith('type') && Array.isArray(this.form.value[key])) {
                if (initialValue[key][0].value) {
                    return this.form.value[key][0].value != initialValue[key][0].value
                } else {
                    return this.form.value[key][0].value != initialValue[key]
                }
            } else {
                return this.form.value[key] != initialValue[key];
            }
        });
    }
}
