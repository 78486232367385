<ng-container *ngIf="(clientService.client$ | async) as client">
    <bg-header
        [toolSwitch]="false"
        [currentModule]="'Performance Console'"
        [userName]="userService.userData$ | async | userData:'fullname'"
        [logoutUrl]="''">

        <div class="headerDropdownSwitch">
            <bg-header-dropdown-switch dropDownName="Client">
                <clients-dropdown></clients-dropdown>
            </bg-header-dropdown-switch>
        </div>

        <div flyin-title>
            <bg-icon size="medium" type="kinesso-logo"></bg-icon>
            <div class="title">Kinesso</div>
        </div>

        <div flyin-content>
            <div class="content-container">
                <div class="menu-group" data-cy="menu-group">
                    <div class="menu-container"
                         (click)="redirect(environment.clUrl + 'clients/' + client.id + '/users')">
                        <div class="menu-left">
                            <div class="menu-icon">
                                <div class="short-title">AS</div>
                            </div>
                            <div class="title">Access & Settings</div>
                        </div>
                    </div>

                    <div class="menu-container"
                         (click)="redirect(environment.fmUrl + '/home?clientId=' + client.id)">
                        <div class="menu-left">
                            <div class="menu-icon">
                                <div class="short-title">FM</div>
                            </div>
                            <div class="title">Feed Management</div>
                        </div>
                    </div>

                    <div class="menu-container"
                         (click)="redirect(environment.daUrl + '/home?clientId=' + client.id)">
                        <div class="menu-left">
                            <div class="menu-icon">
                                <div class="short-title">DA</div>
                            </div>
                            <div class="title">Dynamic Advertising</div>
                        </div>
                    </div>

                    <div class="menu-container"
                         (click)="redirect(environment.cdUrl + '/clients/' + client.id)">
                        <div class="menu-left">
                            <div class="menu-icon">
                                <div class="short-title">CD</div>
                            </div>
                            <div class="title">Content Discovery</div>
                        </div>
                    </div>

                    <div class="menu-container"
                         (click)="redirect(environment.cmUrl)">
                        <div class="menu-left">
                            <div class="menu-icon">
                                <div class="short-title">CM</div>
                            </div>
                            <div class="title">Campaign Management</div>
                        </div>
                    </div>

                    <div class="menu-container"
                         (click)="redirect(environment.atUrl + '/clients/' + client.id + '/overview')">
                        <div class="menu-left">
                            <div class="menu-icon">
                                <div class="short-title">AT</div>
                            </div>
                            <div class="title">Activation Triggers</div>
                        </div>
                    </div>

                    <div class="menu-container active">
                        <div class="menu-left">
                            <div class="menu-icon">
                                <div class="short-title">PC</div>
                            </div>
                            <div class="title">Product Center</div>
                        </div>
                    </div>

                    <div class="menu-container external"
                         (click)="redirect(environment.dxaUrl)">
                        <div class="menu-left">
                            <div class="menu-icon">
                                <div class="short-title">DXA</div>
                            </div>
                            <div class="title">Digital Experience Audit</div>
                        </div>
                        <bg-icon type="output-alt" size="small"></bg-icon>
                    </div>

                    <div class="menu-container external"
                         (click)="window.open(environment.mieUrl, '_blank')">
                        <div class="menu-left">
                            <div class="menu-icon">
                                <div class="short-title">MIE</div>
                            </div>
                            <div class="title">Marketing Intelligence Engine</div>
                        </div>
                        <bg-icon type="output-alt" size="small"></bg-icon>
                    </div>
                </div>

                <div class="fly-in-footer">
                    <div class="user-profile-section" (bgClickOutside)="userMenuToggle = false">
                        <div class="icon-group" (click)="userMenuToggle = !userMenuToggle">
                            <div class="footer-icon">
                                <div class="short-title">{{userService.userData$ | async | userData:'initials'}}</div>
                            </div>
                            <div class="settings-icon">
                                <bg-icon size="small" color="#627594" type="settings"></bg-icon>
                            </div>
                        </div>

                        <div class="user-dropdown-container" *ngIf="userMenuToggle">
                            <div class="menu-dropdown-item menu-dropdown-item-active"
                                 (click)="logoff()">
                                <span>Log out</span>
                            </div>
                        </div>
                    </div>
                    <div class="copyrights">© Marketing Intelligence Engine, {{currentYear}}</div>
                </div>
            </div>
        </div>
    </bg-header>

    <bg-side-menu>
        <bg-menu-item menuItemText="Product Center"
                      [routerLink]="['clients', client.id, 'product-center']"
                      routerLinkActive #overview="routerLinkActive"
                      [menuItemSelected]="overview.isActive">
        </bg-menu-item>
        <bg-menu-item menuItemText="Mapping"
                      [routerLink]="['clients', client.id, 'mapping']"
                      routerLinkActive #mapping="routerLinkActive"
                      [menuItemSelected]="mapping.isActive">
        </bg-menu-item>
        <bg-menu-item menuItemText="Import Settings"
                      [routerLink]="['clients', client.id, 'import-settings']"
                      routerLinkActive #importSettings="routerLinkActive"
                      [menuItemSelected]="importSettings.isActive">
        </bg-menu-item>
    </bg-side-menu>
</ng-container>
