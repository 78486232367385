<bg-dropdown-selector
    [hasBorder]="false"
    (optionClickEvent)="handleClientSelection($event)"
    [singleOptions]="clientOptions"
    [searchBar]="true"
    [width]="200"
    noMatchFoundMessage="No matches found"
    searchBarPlaceholder="Type to search clients"
    noDataAvailableMessage="No clients available"
    noMatchFoundIcon="search-no-results"
    noDataAvailableIcon="delete"
    data-cy="clientDropdown">
</bg-dropdown-selector>
