import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {MappingTableRow} from '../mapping-table/mapping-table.component';

// /**
//  * Validation RegExp for lowercase, numbers and underscores only.
//  */
// export const INPUT_VALIDITY: RegExp = /^[a-z0-9_]*$/;
// export const INPUT_ERROR: string = 'The input does not match the required format: lowercase, numbers and underscores';

export declare type OptionalValidationErrors = ValidationErrors | null;

export function mappingInputsValidator(rows: MappingTableRow[]): ValidatorFn {
    const hasDuplicates: (value: string) => boolean = (value: string): boolean => {
        let duplicate: number = 0
        rows.forEach((row: MappingTableRow): void =>{
            if(row.storeColumnValue === value){
                duplicate++;
            }
        });
        return duplicate > 1;

    };

    return (control: AbstractControl): OptionalValidationErrors => {
        const validationErrors: OptionalValidationErrors = inputValidator(control);
        if (validationErrors !== null) {
            return validationErrors;
        }

        if (hasDuplicates(control.value)) {
            return duplicatesError();
        }

        return null;
    }
}

export const inputValidator: ValidatorFn = (control: AbstractControl): OptionalValidationErrors => {
    if (!control.value) {
        return requiredError();
    }

    // if (!INPUT_VALIDITY.test(control.value)) {
    //     return inputError();
    // }
    return null;
};

export declare type RequiredError = ValidationErrors & { isRequired: string };
export const requiredError: (message?: string) => RequiredError = (message?: string): RequiredError => {
    return {isRequired: message ?? 'This field is required'};
}

// export declare type InputError = ValidationErrors & { inputInvalid: string };
// export const inputError: (message?: string) => InputError = (message?: string): InputError => {
//     return {inputInvalid: message ?? INPUT_ERROR};
// }

export type DuplicatesError = ValidationErrors & { duplicateEntry: string };
export const duplicatesError: (message?: string) => DuplicatesError = (message?: string): DuplicatesError => {
    return {duplicateEntry: message ?? 'This item already exists'};
}

