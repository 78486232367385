import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {LOCAL_DEVELOPER, UserData} from '../../pipes/user-data/user.data.type';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {User} from '../../model/user';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private readonly _userData: ReplaySubject<UserData> = new ReplaySubject<UserData>(1);
    public readonly userData$: Observable<UserData> = this._userData.asObservable();

    constructor(private http: HttpClient) {
    }

    setLocalUser() : void {
        this._userData.next(LOCAL_DEVELOPER);
    }

    setUser(user: UserData) : void {
        this._userData.next(user);
    }

    fetchUser(): Observable<User> {
        return this.http.get<User>(environment.apiUrl + '/whoami');
    }
}
