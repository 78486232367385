import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, Router, UrlTree} from '@angular/router';
import {ClientService} from '../client.service';
import {Client} from '../client.type';
import {catchError, map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';

export type ClientGuardFn = (childRoute: ActivatedRouteSnapshot) => Observable<boolean | UrlTree>;
export const clientGuard: ClientGuardFn = (route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> => {
    const router: Router = inject(Router);
    const createLoginUrlTree = (): Observable<UrlTree> => of(router.createUrlTree(['/', 'login']));
    const clientService: ClientService = inject(ClientService);
    const clientId: number | undefined = route.params['clientId'];
    if (clientId) {
        return clientService.fetchClient(clientId)
            .pipe(
                map((client: Client): boolean => {
                    clientService.setCurrentClient(client);
                    return true;
                }),
                catchError(() => createLoginUrlTree())
            )
    }
    return createLoginUrlTree();
}
