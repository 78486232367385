import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MappingTableComponent} from './mapping-table.component';
import {ReactiveFormsModule} from '@angular/forms';
import {BgDropdownListModule, BgInputModule} from '@bgenius/bgf-angular';
import {ColumnTypePipe} from '../pipes/column-type/column.type.pipe';

@NgModule({
    declarations: [
        MappingTableComponent,
        ColumnTypePipe
    ],
    exports: [MappingTableComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        BgInputModule,
        BgDropdownListModule
    ]
})
export class MappingTableModule {
}
