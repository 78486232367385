<table>
    <thead class="main-header">
    <tr>
        <th *ngFor="let tableHeader of tableHeaders">{{tableHeader}}</th>
    </tr>
    </thead>
    <tbody [formGroup]="form" *ngIf="!loading">
    <tr *ngFor="let row of rows">
        <td>
            <bg-input [disabled]="true" [content]="row.importColumnValue ? row.importColumnValue : ''"
                      data-cy="resource"></bg-input>
        </td>
        <td>
            <bg-input [formControlName]="row.storeColumnValue"
                      (inputChange)="updateNameValue(row, $event)"
                      data-cy="store"></bg-input>
        </td>
        <td>
            <div *ngIf="typeNotAvailable">
                {{row.storeColumnType | columnTypePipe}}
                <div data-cy="type"></div>
            </div>
            <div *ngIf="!typeNotAvailable">
                <bg-dropdown-list
                    data-cy="dropdown"
                    [formControlName]="'type' + row.storeColumnValue"
                    [availableOptions]="availableTypes[row.importColumnValue]"
                    (optionClickEvent)="updateTypeValue(row, $event)"
                ></bg-dropdown-list>
            </div>
        </td>
    </tr>
    </tbody>
</table>
